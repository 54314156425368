//教師排課配置
const scheduleConfig = {
  is_current: false,
  is_next: false,
  is_selected: true,
};

//打卡配置
const teacherClockConfig = {};
export { scheduleConfig, teacherClockConfig };
