<template>
  <div>
    <!-- ... 教师修改設定密碼 ... -->
    <div class="pup_container_form get_password_step_2" v-if="showStep==6">
      <div class="pup_form_bg" @click="setDialogStep(0)"></div>
      <div class="pup_form_container">
        <div class="pup_form_title">重新設定密碼</div>
        <div class="pup_form_scroll">
          <div class="form_input_container">
            <div class="form_input_title">輸入目前密碼</div>
            <div class="form_input">
              <input placeholder v-model="teacherPassword.old_password" type="password" />
            </div>
          </div>
          <div class="form_input_container">
            <div class="form_input_title">輸入新密碼</div>
            <div class="form_input">
              <input placeholder type="password" v-model="teacherPassword.new_password" />
            </div>
          </div>
          <div class="form_input_container">
            <div class="form_input_title">確認新密碼</div>
            <div class="form_input">
              <input placeholder type="password" v-model="teacherPassword.new_password" />
            </div>
            <div class="form_input_tip" v-if="errTeacherTips">兩次密碼輸入不一致</div>
          </div>
        </div>
        <img
          alt
          class="close_icon"
          @click="setDialogStep(0)"
          src="../../assets/image/close_icon_green.png"
        />
        <div class="pup_form_btn get_password_step_2_send" @click="sendTeacherMessage">確認送出</div>
      </div>
    </div>
    <!-- ... 教师修改設定密碼end ... -->
    <!-- ... 重新設定密碼-身分驗證 ... -->
    <div class="pup_container_form get_password_step_1" v-if="showStep==1">
      <div class="pup_form_bg" @click="setDialogStep(0)"></div>
      <div class="pup_form_container">
        <div class="pup_form_title">重新設定密碼</div>
        <div class="pup_form_scroll">
          <div class="form_input_container">
            <div class="form_input_title">會員註冊手機號碼</div>
            <div class="form_input">
              <input placeholder v-model="resetPassword.phoneNum" type="text" />
            </div>
          </div>
          <div class="pup_form_tip">將傳送簡訊驗證碼確認會員身分</div>
        </div>
        <img
          alt
          class="close_icon"
          @click="setDialogStep(0)"
          src="../../assets/image/close_icon_green.png"
        />
        <div class="pup_form_btn get_password_step_1_send" @click="sendPassword(2)">確認送出</div>
      </div>
    </div>
    <!-- ... 重新設定密碼-身分驗證end ... -->
    <!-- ... 重新設定密碼 ... -->
    <div class="pup_container_form get_password_step_2" v-if="showStep==2">
      <div class="pup_form_bg" @click="setDialogStep(0)"></div>
      <div class="pup_form_container">
        <div class="pup_form_title">重新設定密碼</div>
        <div class="pup_form_scroll">
          <div class="form_input_container">
            <div class="form_input_title">設定密碼</div>
            <div class="form_input">
              <input placeholder v-model="resetPassword.password" type="password" />
            </div>
          </div>
          <div class="form_input_container">
            <div class="form_input_title">再次輸入確定密碼</div>
            <div class="form_input">
              <input placeholder type="password" v-model="surePassword" />
            </div>
            <div class="form_input_tip" v-if="errTips">確定密碼與密碼輸入不一致</div>
          </div>
          <div class="form_input_container">
            <div class="form_input_title">簡訊驗證碼</div>
            <div class="form_input">
              <input v-model="resetPassword.code" placeholder type="text" />
            </div>
          </div>
          <div class="pup_form_tip">
            沒有收到簡訊？
            <a @click="getSmsCode('5')">重新發送</a>
          </div>
        </div>
        <img
          alt
          class="close_icon"
          @click="setDialogStep(0)"
          src="../../assets/image/close_icon_green.png"
        />
        <div class="pup_form_btn get_password_step_2_send" @click="sendSmscode(2)">確認送出</div>
      </div>
    </div>
    <!-- ... 重新設定密碼end ... -->
    <!-- ... 設定密碼成功 ... -->
    <div class="pup_container_form get_password_step_3" v-if="showStep==3">
      <div class="pup_form_bg" @click="setDialogStep(0)"></div>
      <div class="pup_form_container">
        <div class="pup_form_text">密碼設定成功</div>
        <div class="pup_form_text_tip">請以新的密碼作會員登入</div>
        <div class="pup_form_btn get_password_step_3_send" @click="setDialogStep(0)">關閉</div>
      </div>
    </div>
    <!-- ... 設定密碼成功end ... -->
    <!-- ... pup視窗-上傳頭像 ... -->
    <div class="pup_container_form pup_headpic" v-if="showStep==5">
      <div class="pup_form_bg" @click="setDialogStep(0)"></div>
      <div class="pup_form_container">
        <div class="pup_headpic_container">
          <div>
            <img :src="avatarUrl" alt />
          </div>
        </div>
        <div class="pup_headpic_upload">
          <input accept="image/jpg, image/jpeg, image/png" type="file" @change="readFiles($event)" />選擇圖片
        </div>
        <img
          alt
          class="close_icon"
          @click="setDialogStep(0)"
          src="../../assets/image/close_icon_green.png"
        />
        <div id="sendFile" class="pup_form_btn" @click="updateAvatar">確認上傳</div>
      </div>
    </div>
    <!-- ... pup視窗-上傳頭像end ... -->
  </div>
</template>
<script>
import {
  getHsdForgetPassword,
  getHsdUpdatePassword,
  getHsdCulum,
  getHsdSmsCode,
  getHsdUserPhoto,
  getTeacherUpdatePhoto,
  getTeacherPassword,
} from "../../api/api";
import defaultImg from "@/assets/image/member_headpic_default.jpg";
import { Message } from "element-ui";
export default {
  props: ["showStep", "avatar", "templateCode"],
  data() {
    return {
      defaultImg,
      errTips: false,
      errTeacherTips: false,
      surePassword: "",
      resetPassword: {
        code: "",
        codeId: "",
        templateCode: "",
        countryCode: "86",
        phoneNum: "",
        password: ""
      },
      teacherPassword: {},
      avatarUrl: defaultImg
    };
  },
  watch: {
    avatar(nV) {
      this.avatarUrl = nV;
    }
  },
  created() {},
  methods: {
    //修改dialog
    setDialogStep(id) {
      this.$emit("changeStep", id);
    },
    //获取短信验证码
    async getSmsCode() {
      this.resetPassword.templateCode = this.templateCode;
      const { countryCode, templateCode, phoneNum } = this.resetPassword;
      //13872250190
      const res = await getHsdSmsCode({ countryCode, templateCode, phoneNum });
      if (res && res.code == 200 && res.success == 1) {
        this.setDialogStep(2);
        this.resetPassword.codeId = res.data.code_id;
      } else {
        Message({
          type: "error",
          message: res.msg
        });
      }
    },
    //发送手机号获取验证码
    sendPassword(id) {
      this.getSmsCode();
    },
    //重置密码 Success
    async sendSmscode() {
      if (this.resetPassword.password !== this.surePassword) {
        this.errTips = true;
        return;
      }
      this.errTips = false;
      const res = await getHsdForgetPassword(this.resetPassword);
      if (res && res.code == 200 && res.success == 1) {
        this.setDialogStep(0);
        this.$router.push("/login");
      }
    },
    //教师修改密码
    async sendTeacherMessage() {
      if (
        this.teacherPassword.old_password !== this.teacherPassword.new_password
      ) {
        this.errTeacherTips = true;
        return;
      }
      const res = await getTeacherPassword(this.teacherPassword);
      if (res && res.code == 200) {
        this.setDialogStep(0);
        this.$router.push("/login");
      }
    },
    //图片转换成base64
    readFiles(e) {
      var _this = this;
      var imgLimit = 1024;
      var files = e.target.files;
      var image = new Image();
      if (files.length > 0) {
        var dd = 0;
        if (files.item(dd).size > imgLimit * 102400) {
          //to do sth
        } else {
          image.src = window.URL.createObjectURL(files.item(dd));
          image.onload = function() {
            // 默认按比例压缩
            var w = image.width,
              h = image.height,
              scale = w / h;
            w = 200;
            h = w / scale;
            // 默认图片质量为0.7，quality值越小，所绘制出的图像越模糊
            var quality = 0.7;
            //生成canvas
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            // 创建属性节点
            var anw = document.createAttribute("width");
            anw.nodeValue = w;
            var anh = document.createAttribute("height");
            anh.nodeValue = h;
            canvas.setAttributeNode(anw);
            canvas.setAttributeNode(anh);
            ctx.drawImage(image, 0, 0, w, h);
            var ext = image.src
              .substring(image.src.lastIndexOf(".") + 1)
              .toLowerCase(); //图片格式
            var base64 = canvas.toDataURL("image/" + ext, quality);
            _this.avatarUrl = base64;
            // 回调函数返回base64的值
            // if (_this.imgArr.length <= 4) {
            //   _this.imgArr.unshift("");
            //   _this.imgArr.splice(0, 1, base64); //替换数组数据的方法，此处不能使用：this.imgArr[index] = url;
            //   if (_this.imgArr.length >= 5) {
            //     _this.allowAddImg = false;
            //   }
            // }
          };
        }
      }
    },
    //上传图片
    async updateAvatar() {
      try {
        let photo = this.avatarUrl.split(",")[1];
        let dl = { photo: photo };
        let res;
        if (this.templateCode == "5") {
          res = await getHsdUserPhoto(dl);
        } else {
          res = await getTeacherUpdatePhoto(dl);
        }
        if (res && res.success == 1) {
          this.$emit("changeStep", { id: 0, url: this.avatarUrl });
          // this.currDialog`Id = null;`
          Message({
            message: "圖像上傳成功"
          });
        }
      } catch (err) {}
    }
  }
};
</script>
<style scoped>
</style>