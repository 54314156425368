<template>
  <div>
    <!-- 最上端黑色橫bar -->
    <div class="top_bar">
      <div class="top_bar_container">
        <div class="top_bar_left">客服專線:(02)2311-3260</div>
        <div class="top_bar_right">
          <a href="https://www.facebook.com/myteacher12345" target="_blank">
            <img alt src="../../assets/image/fb_icon.png" />
            <span>粉絲團</span>
          </a>
          <a
            href="https://www.youtube.com/channel/UCC42p8RfYnd_sACu_KoVETQ/"
            target="_blank"
          >
            <img alt src="../../assets/image/yt_icon.png" />
            <span>影音頻道</span>
          </a>
          <a target="_blank" href="https://www.104.com.tw/company/1a2x6biida">
            <img alt src="../../assets/image/104_icon.png" />
            <span>人才招募</span>
          </a>
        </div>
      </div>
    </div>
    <!-- 最上端黑色橫bar end -->

    <div class="member_main_area">
      <div class="member_main_container">
        <div class="member_nav_container">
          <div class="member_nav_logo">
            <a target="_blank">
              <img src="../../assets/image/member_login_logo.png" alt />
            </a>
          </div>
          <div
            class="member_nav_btn_open"
            id="btnOpen"
            @click="toggleNavlist"
            role="button"
          >
            教師資訊
          </div>

          <div class="teacher_nav_account_container">
            <div class="teacher_nav_account">
              <div class="teacher_nav_headpic">
                <div>
                  <img :src="avatarUrl" alt />
                  <img
                    src="../../assets/image/gold_medal.png"
                    class="teacher_medal"
                    alt
                  />
                  <!-- 教師分3等級對應3圖檔gold_medal silver_medal bronze_medal" -->
                </div>
              </div>
              <div class="teacher_nav_infor">
                <div>
                  <span class="display_none_600">{{
                    baseUserInfo.fullName
                  }}</span>
                  {{ baseUserInfo.cellPhone }}
                  <span
                    class="teacher_account_setting"
                    @click="memberSetting = !memberSetting"
                    >帳號管理</span
                  >
                </div>
                <div>
                  <span>
                    <span class="teacher_class_setting" @click="openSchedule"
                      >可排課時間</span
                    >
                    <img
                      src="../../assets/image/yellow_line.jpg"
                      class="yellow_line"
                      alt
                    />
                    <span
                      class="teacher_point_record"
                      @click="salaryRecord = true"
                      >薪資紀錄</span
                    >
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- 會員資訊 -->
          <div class="member_nav_btn_container" id="showNavCont">
            <div class="member_nav_btn nav_btn_news" @click="showNewsDialog(1)">
              最新消息
              <div class="member_news_notice" v-if="isNewNoticeType"></div>
            </div>
            <div class="member_nav_btn nav_btn_rule" @click="showNewsDialog(2)">
              上課公約
            </div>
            <div
              class="member_nav_btn nav_btn_device"
              @click="showNewsDialog(3)"
            >
              設備檢測
            </div>
            <div class="member_nav_btn nav_btn_qa" @click="showNewsDialog(4)">
              常見問題
            </div>
          </div>
          <!-- 會員資訊end -->
          <!-- ... 會員資訊選項展開收起 ... -->
          <!-- 帳號設定 -->
          <div class="member_setting_window" v-if="memberSetting">
            <div class="member_setting_container">
              <div class="member_setting_headpic">
                <img :src="avatarUrl" alt />
                <img
                  src="../../assets/image/gold_medal.png"
                  class="teacher_medal"
                  alt
                />
                <!-- 教師分3等級對應3圖檔gold_medal silver_medal bronze_medal" -->
              </div>
              <div class="member_setting_name">{{ baseUserInfo.fullName }}</div>
              <div class="teacher_setting_account">
                {{ baseUserInfo.cellPhone }}
              </div>
              <div class="teacher_class_infor_contianer">
                <div class="teacher_class_infor">
                  <span>上課時數</span>
                  <br />217
                </div>
                <div class="teacher_class_infor">
                  <span>評價星數</span>
                  <br />4.5
                  <img src="../../assets/image/star_pic.png" alt />
                </div>
              </div>
              <div
                class="member_setting_btn headpic_upload_open"
                @click="sendUserAvatar(5)"
              >
                上傳頭像
              </div>
              <div
                class="member_setting_btn reset_passowrd_btn"
                @click="updatePassword"
              >
                修改密碼
              </div>
              <div class="member_setting_btn" @click="teacherLoginout">
                帳號登出
              </div>
              <div
                class="member_setting_window_close"
                @click="memberSetting = false"
              >
                關閉
              </div>
            </div>
          </div>
          <!-- 帳號設定end -->
        </div>
        <teacher-check-table></teacher-check-table>
      </div>
    </div>

    <div class="system_talk_area" style>
      <img
        src="../../assets/image/system_talk_close.png"
        class="system_talk_close"
        alt
      />
      <div class="system_talk_window">
        <img src="https://qr-official.line.me/sid/M/rqf1464u.png" />
      </div>
    </div>

    <!-- ... 線上客服end ... -->

    <!-- 顶部消息弹窗 -->
    <common-temp
      ref="commonNews"
      :topNavId.sync="topNavDialogId"
      :userInfo="noticeData"
    ></common-temp>
    <!-- ... 修改用戶信息 ... -->
    <change-user-info
      templateCode="6"
      :showStep="currDialogId"
      :avatar="avatarUrl"
      @changeStep="sendUserAvatar"
    ></change-user-info>
    <!-- ... pup視窗-薪資紀錄 ... -->
    <div class="teacher_pup_point_record" v-if="salaryRecord">
      <div class="member_pup_bg" @click="salaryRecord = false"></div>
      <div class="member_pup_container">
        <div class="member_pup_title">薪資紀錄</div>
        <div class="member_pup_main">
          <div class="teacher_point_top_container">
            <div class="teacher_point_record_search">
              <el-date-picker
                type="month"
                v-model="incomeParam.logMonth"
                value-format="yyyy-MM"
                :picker-options="currentYear"
                placeholder="選擇月份"
                @change="getPayList"
              ></el-date-picker>
            </div>
            <div class="teacher_point_have_now">
              本月累計薪資：
              <span>{{ incomeList.totalIncomeAmount }}</span
              >&nbsp;元
            </div>
          </div>

          <div class="teacher_point_tip">
            點數換算：1點={{ incomeList.pointUnitPrice }}元&nbsp;每月1號結算薪資
          </div>
          <div class="teacher_point_tip">
            當月收入點數{{ incomeList.TotalClassPoint }}(當月獲得{{
              incomeList.TotalClassPoint
            }}+上月未處理{{ incomeList.LastClassPoint }})
          </div>
          <div class="teacher_point_record_title_contianer">
            <div class="teacher_point_record_title_date">上課日期</div>
            <div class="teacher_point_record_title_count">點數</div>
            <div class="teacher_point_record_title_memo">課程時間</div>
          </div>

          <div
            class="teacher_point_main"
            v-if="incomeList.classOrderData && incomeList.classOrderData.length"
          >
            <div
              v-for="(inco, i) in incomeList.classOrderData"
              :key="i"
              class="teacher_point_record_contianer"
            >
              <div class="teacher_point_record_date">{{ inco.classDate }}</div>
              <div class="teacher_point_record_count">{{ inco.classUnitPoint }}</div>
              <div class="teacher_point_record_memo">{{ inco.classTitle }}</div>
            </div>
          </div>
          <div class="noDataTip" v-else>暫無紀錄</div>
        </div>
        <img
          alt
          class="member_pup_close"
          @click="salaryRecord = false"
          src="../../assets/image/close_icon.png"
        />
      </div>
    </div>
    <!-- ... pup視窗-薪資紀錄end ... -->

    <!-- ... pup視窗-排課時間 ... -->
    <div class="pup_container_class_schedule" v-if="isTeacher">
      <div class="pup_form_bg" @click="closeTeacherClass"></div>
      <div class="pup_form_schedule">
        <div class="pup_schedule_select">
          <el-date-picker
            type="month"
            v-model="selectDate"
            value-format="yyyy-MM"
            :picker-options="currentYear"
            placeholder="選擇月份"
            @change="changeScheduleMonth"
          ></el-date-picker>
        </div>
        <div class="days-of-modal-week">
          <div class="header-day" v-for="(d, k) in weeksList" :key="k">
            {{ d }}
          </div>
        </div>
        <div class="pup_schedule_list" style="padding-top:0">
          <div class="pup_schedule_list_title">
            <span
              >{{ isDisabled ? "查看" : "選擇"
              }}{{ selectMth }}月可排課時間(14:00-21:00&nbsp;每時段1小時)</span
            >
          </div>
          <div
            class="pup_schedule_time_container"
            style="display: flex; flex-wrap: wrap;"
            v-if="
              (scheduleList.length || scheduleListNext.length) &&
              currentMonthDate
            "
          >
            <div class="schedule_calander" v-for="(line, k) in currentMonthDate" :key="k">
              <div class="pup_schedule_time calanderCon" v-for="days in line" :key="days">
                <div class="pup_schedule_time_title">
                  <span>{{ days }}日</span>
                  <span>
                    <el-checkbox
                      :disabled="isDisabled"
                      v-model="selectAll[days - 1]"
                      @change="selectAlltime($event, days - 1)"
                    />
                    <label for="d01_all">全選</label>
                  </span>
                </div>
                <div class="pup_schedule_time_choice_container">
                  <div
                    class="pup_schedule_time_choice"
                    v-for="(times, i) in teacherSelectT"
                    :key="times"
                  >
                    <span>
                      <span>
                        <input
                          type="checkbox"
                          :disabled="isDisabled"
                          v-model="selectTimes[days - 1][i]"
                          @change="selectTimesInterval(days - 1)"
                        />
                      </span>

                      <label for="d01_14-15">{{
                        `${times}-${times + 1}`
                      }}</label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          alt
          class="close_icon"
          @click="closeTeacherClass"
          src="../../assets/image/close_icon_green.png"
        />
        <div>
          <div
            class="pup_form_schedule_btn"
            @click="beginSetClass"
            v-if="isDisabled"
          >
            開啟排課
          </div>
          <div
            class="pup_form_schedule_btn"
            @click="savaClassTimes"
            v-if="!isDisabled"
          >
            儲存排課
          </div>
        </div>
        <!--16-25號之間排課可用-->
        <!--
        <div
          class="pup_form_schedule_btn"
          @click="savaClassTimes"
          v-if="isScheduleAvalable"
        >
          儲存排課
        </div>
        <div v-else>
          <div class="pup_form_schedule_btn" style="background: #b5b5b5">
            開啟排課
          </div>
        </div>
        -->
        <!-- 按下開啟排課後 input type="checkbox"拿掉disabled="disabled"-->
      </div>
    </div>
    <!-- ... pup視窗-排課時間end ... -->
  </div>
</template>
<script>
import teacherCheckTable from "../childComponent/teacher_check_table";
import changeUserInfo from "../childComponent/change_user_info";
import commonTemp from "../childComponent/common_news_temp";
import * as config from "./teacherConfig";
import {
  getTeacherLogout,
  getPayIncomeList,
  getTeacherBaseinfo,
  getTeacherScheduleList,
  saveTeacherScheduleList,
  getHsdNoticesType,
} from "../../api/api";
export default {
  data() {
    return {
      isNewNoticeType: false,
      noticeSearch: { pageNum: 1, pageSize: 10 },
      noticeData: { userId: "", noticeType: "2" },
      videoPlay: false, //课程资源播放
      salaryRecord: false, //薪资记录
      isTeacher: false, //教師排課時間窗口
      selectMth: 0, //排课月
      selectYear: 0,
      selectDate: "", //选择排课日期
      topNavDialogId: 0,
      userAvatar: "",
      currDialogId: null,
      memberSetting: false,
      currentMonthDays: 0, //當前月的天数
      currentMonthDate: {},
      teacherSelectT: [
        //排課時間段
        14,
        15,
        16,
        17,
        18,
        19,
        20,
      ],
      weeksList: ["日", "一", "二", "三", "四", "五", "六"],
      scheduleList: [], //排课时间
      scheduleListNext: [], //下个月排课时间
      checked: true,
      isDisabled: true, //开启排课
      selectAll: [], //全选
      selectTimes: {}, //所选时间
      selectAllNext: [], //全选下个月
      selectTimesNext: {}, //所选时间下个月
      isScheduleAvalable: false,
      baseUserInfo: {},
      avatarUrl: "",
      incomeList: [],
      incomeParam: { logMonth: "" },
      currentYear: {
        disabledDate: (month) => {
          return false;
          // return month.getFullYear() != new Date().getFullYear();
        },
      },
    };
  },
  components: { commonTemp, changeUserInfo, teacherCheckTable },
  watch: {
    selectMth(nV) {
      this.currentMonthDate = {}
      this.currentMonthDays = new Date(this.selectYear, nV, 0).getDate();
      let days = new Date(this.selectYear, nV - 1, 1).getDay();console.log(nV,days)
      let rows = Math.ceil((this.currentMonthDays - (7 - days)) / 7) + 1,
        sumDate = 1;
      for (let i = 0; i < rows; i++) {
        let j = i == 0 ? 7 - days : 7,
          currList = [];
        for (let d = 0; d < j; d++) {
          if(sumDate > this.currentMonthDays) break;
          currList.push(sumDate);
          sumDate = sumDate + 1;
        }
        this.$set(this.currentMonthDate, i, currList);
        if(sumDate > this.currentMonthDays) break;
      }
    },
    checked(nV) {
      if (nV) {
        return nV;
      } else {
        return !nV;
      }
    },
  },
  created() {
    this.getTeacherInfo();
    let year = new Date().getFullYear(),
      month = new Date().getMonth(),
      days = new Date().getDate();
    this.isScheduleAvalable = days > 16 && days <= 25 ? true : false;
    this.selectYear = year
    this.selectMth = month + 1;
    let yearMonth =
      year + "-" + (month + 1 < 10 ? "0" + (month + 1) : month + 1);
    this.incomeParam.logMonth = yearMonth;
    this.selectDate = yearMonth;
    this.getPayList();
  },
  methods: {
    //咨询显示隐藏
    toggleNavlist() {
      let content = document.getElementById("showNavCont"),
        btn = document.getElementById("btnOpen");
      let display;
      if (content.currentStyle) {
        display = content.currentStyle.display;
      } else {
        display = window.getComputedStyle(content, false).display;
      }

      if (display == "block") {
        content.classList.remove("nav-content");
        btn.classList.remove("active");
      } else {
        content.classList.add("nav-content");
        btn.classList.add("active");
      }
    },
    //获取薪资记录
    async getPayList() {
      const res = await getPayIncomeList(this.incomeParam);
      if (res && res.success == 1) {
        this.incomeList = res.data;
      }
    },
    //教师登出
    async teacherLoginout() {
      const res = await getTeacherLogout();
      if (res && res.success == 1) {
        this.$router.push("/login");
        localStorage.removeItem("token");
      }
    },
    //获取教师基本信息
    async getTeacherInfo() {
      const res = await getTeacherBaseinfo();
      if (res && res.success == 1) {
        this.baseUserInfo = res.data;
        this.avatarUrl = `data:image/png;base64,${res.data.photoUrl}`; //
        this.getNoticeType();
      }
    },
    //消息是否已读
    async getNoticeType() {
      const res = await getHsdNoticesType({
        noticeType: "2",
        userId: this.baseUserInfo.userId,
      });
      if (res && res.success == 1) {
        this.isNewNoticeType = res.data;
      }
    },
    //教师排课初始化
    async getTeacherSchedule() {
      const res = await getTeacherScheduleList({ logMonth: this.selectDate });
      if (res && res.success == 1) {
        this.scheduleList = res.data.Data;
        this.renderSelectData(res.data.Data);
      }
    },
    //选择排课月份
    changeScheduleMonth(m) {
      this.isDisabled = true;
      this.scheduleList = [];
      this.selectYear = parseInt(m.split("-")[0]);
      this.selectMth = parseInt(m.split("-")[1]);
      this.getTeacherSchedule();
    },
    renderSelectData(dataList) {
      this.selectDate = `${dataList[0].year}-${dataList[0].month}`;
      this.selectTimes = {};
      this.selectAll = [];
      for (let i in dataList) {
        let currentDaySchedule = [];
        let classData = dataList[i].classDate;
        let isAll = classData.filter((time) => time.isSchedule);
        if (isAll.length == classData.length) {
          this.selectAll[i] = true;
        } else {
          this.selectAll[i] = false;
        }
        for (let k in classData) {
          currentDaySchedule[k] = classData[k].isSchedule;
        }
        this.$set(this.selectTimes, i, currentDaySchedule);
      }
    },
    //当天排课全选
    selectAlltime(event, d) {
      let isAll = this.selectAll[d];
      let currDayAll = [];
      for (let i = 0; i < this.teacherSelectT.length; i++) {
        if (isAll) {
          currDayAll[i] = true;
        } else {
          currDayAll[i] = false;
        }
      }
      this.$set(this.selectTimes, d, currDayAll);
    },
    //存储排课时间
    savaClassTimes() {
      let month = new Date().getMonth() + 1;
      for (let i in this.selectTimes) {
        for (let t = 0; t < this.selectTimes[i].length; t++) {
          this.scheduleList[i]["classDate"][t]["isSchedule"] = this.selectTimes[
            i
          ][t];
        }
      }
      let y = this.scheduleList[0].year,
        m = this.scheduleList[0].month;
      let scheduleData = { year: y, month: m, Data: this.scheduleList };
      this.getSaveSchedule(scheduleData);
    },
    //排课接口
    async getSaveSchedule(params) {
      const res = await saveTeacherScheduleList(params);
      if (res && res.code == 200) {
        this.isTeacher = false;
        this.isDisabled = true;
        this.$message.success("排課成功");
      }
    },
    beginSetClass() {
      this.isDisabled = false;
      let month_current = new Date().getMonth();
      month_current = month_current + 1; //轉化成接口識別的當月數據
      if (config.scheduleConfig.is_next) {
        this.selectMth = month_current + 1;
      } else if (config.scheduleConfig.is_current) {
        if (this.selectMth == month_current) {
          this.renderSelectData(this.scheduleList);
          return;
        } else {
          this.selectMth = month_current;
        }
      }
      this.getTeacherSchedule();
    },
    //判断复选框状态
    renderCheck(d, t) {
      if (this.selectTimes[this.selectMth][d]) {
        if (this.selectTimes[this.selectMth][d].indexOf(t) >= 0) {
          return true;
        }
      }
      return false;
    },
    //选择排课时间
    selectTimesInterval(d) {
      let selectBool = this.selectTimes[d].filter((bool) => bool);
      if (selectBool.length == this.selectTimes[d].length) {
        this.selectAll[d] = true;
      } else {
        this.selectAll[d] = false;
      }
    },
    //关闭弹窗
    closeTeacherClass() {
      this.isTeacher = false;
      this.isDisabled = true;
      // this.selectMth = this.currentMonth;
    },
    //打开排课弹窗
    openSchedule() {
      // this.$router.push('/teacherSchedule')
      this.getTeacherSchedule();
      this.isTeacher = true;
    },
    //头部弹窗显示
    showNewsDialog(id) {
      this.topNavDialogId = id;
      if (id == 1) {
        this.isNewNoticeType = false;
        this.$refs["commonNews"].getNoticeList();
      }
    },
    //头部弹窗关闭
    closeDialog() {
      this.topNavDialogId = 0;
    },
    //上传用户头像
    sendUserAvatar(param) {
      if (param.url) {
        this.currDialogId = param.id;
        this.avatarUrl = param.url;
      } else this.currDialogId = param;
      this.memberSetting = false;
    },
    //打开修改密码弹窗
    updatePassword() {
      this.currDialogId = 6;
      this.memberSetting = false;
    },
    //上传图片
    // async updateAvatar(dl) {
    //   try {
    //     const res = await getHsdUserPhoto(dl);
    //     if (res && res.code == 200) {
    //       this.currDialogId = null;
    //       Message({
    //         showClose: true,
    //         message: "用戶圖像上傳成功",
    //         type: "success"
    //       });
    //       this.getUserInfo();
    //     }
    //   } catch (err) {}
    // }
  },
};
</script>
<style >
@import "../../assets/css/member_center.css";
@import "../../assets/css/clndr.css";
.calanderCon {
  width: calc(14.2857% - 3px);
  margin-right: 3px;
}
.schedule_calander{
  display: flex; 
  width: 100%;
}
.schedule_calander:first-child {
  justify-content: flex-end;
}
.schedule_calander:last-child {
  justify-content: flex-start;
}
.top_bar {
  width: 100%;
  height: 35px;
  background-color: #202020;
}
.nav-content {
  display: block;
}
@media (max-width: 500px) {
  .nav-content {
    display: block;
  }
}
.el-input--prefix .el-input__inner {
  border: 1px solid #9e9e9e !important;
  color: #757575;
}

.el-input__inner::-webkit-input-placeholder {
  color: #757575;
}
.days-of-modal-week {
  margin: 15px 0;
  background: #018247;
  display: flex;
}
.days-of-modal-week .header-day {
  flex: 1;
  padding: 10px 14px;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  border-right: 3px solid #ffffff;
}
</style>

