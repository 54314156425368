<template>
  <div>
    <div class="member_lesson_infor">
      <div class="member_lesson_tip_container">
        <div class="member_lesson_tip">
          <!-- <img src="../../assets/image/time_icon.jpg" alt /> -->
          {{timeClock}}
        </div>
        <div
          class="member_lesson_tip"
        >即將上課：&nbsp;{{lastTimeFilter(1,lastOrderTip.classDate) }}&nbsp;{{ lastTimeFilter(2,lastOrderTip.classTime) }}{{lastTimeFilter(3,lastOrderTip.classTime)}}&nbsp;{{lastOrderTip.classTitle}}</div>
      </div>
      <ul class="tabs">
        <!-- <li class="tab_li" :class="{'active':teacherTableId==4}" @click="teacherTableId=4">上課錄影</li> -->
        <li class="tab_li" :class="{'active':teacherTableId==3}" @click="teacherTableId=3">備課資源</li>
        <li class="tab_li" :class="{'active':teacherTableId==2}" @click="teacherTableId=2">基本資料</li>
        <li class="tab_li" :class="{'active':teacherTableId==1}" @click="teacherTableId=1">我的課表</li>
      </ul>
      <div class="member_lesson_infor_container">
        <!-- 我的課表 -->
        <div id="infor1" v-if="teacherTableId==1">
          <class-order-table
            ref="classOrder"
            :studentClassData="classOrderData"
            :studentDetail="classDetailList"
            @getClassOrder="getTeacherClassList"
            @setClassDetailData="setTeaclassDetail"
            @setDefaultClassOrder="setClassOrderArr"
            @showDialog="changeDialogId"
            @setTeaOrder="setOrderFromChild"
            :classId="2"
          ></class-order-table>
        </div>
        <!-- 我的課表end -->
        <!-- 基本資料 -->
        <div id="infor2" v-if="teacherTableId==2">
          <div class="member_lesson_infor_title">基本資料</div>
          <div class="personal_data_container">
            <div class="personal_data_input_container">
              <div class="personal_data_input_title">老師姓名</div>
              <div class="personal_data_input">
                <input type="text" v-model="teacherMessage.fullName" readonly class="input_lock" />
              </div>
            </div>
            <div class="personal_data_input_container">
              <div class="personal_data_input_title">最高學歷</div>
              <div class="personal_data_input">
                <input type="text" v-model="teacherMessage.education" readonly class="input_lock" />
              </div>
            </div>
            <div class="personal_data_input_container">
              <div class="personal_data_input_title">主修科系</div>
              <div class="personal_data_input">
                <input type="text" v-model="teacherMessage.major" readonly class="input_lock" />
              </div>
            </div>
            <div class="personal_data_input_container">
              <div class="personal_data_input_title">身分字號</div>
              <div class="personal_data_input">
                <input
                  type="text"
                  v-model="teacherMessage.idCardNumber"
                  readonly
                  class="input_lock"
                />
              </div>
            </div>
            <div class="personal_data_input_container">
              <div class="personal_data_input_title">手機號碼</div>
              <div class="personal_data_input">
                <input type="text" v-model="teacherMessage.cellPhone" readonly class="input_lock" />
              </div>
            </div>
            <div class="personal_data_input_container">
              <div class="personal_data_input_title">聯絡地址</div>
              <div class="personal_data_input">
                <input
                  type="text"
                  v-model="teacherMessage.contactAddress"
                  readonly
                  class="input_lock"
                />
              </div>
            </div>
            <div class="personal_data_input_container">
              <div class="personal_data_input_title">電子信箱</div>
              <div class="personal_data_input">
                <input type="text" v-model="teacherMessage.email" readonly class="input_lock" />
              </div>
            </div>

            <div style="clear:both;"></div>

            <div class="personal_data_checkbox_container">
              <div class="personal_data_input_title">擅長科目</div>
              <div>
                <div v-for="(leasson,i) in subjectList" :key="i" class="personal_data_checkbox">
                  <input type="checkbox" v-model="subjectList[i].isTrue" :disabled="true" />
                  <label for>{{leasson.subjectName}}</label>
                </div>
              </div>
            </div>

            <div class="personal_data_textarea_container">
              <div class="personal_data_input_title">教學經歷</div>
              <div>
                <div class="personal_data_textarea">
                  <textarea
                    v-model="teacherMessage.teachingExperience"
                    :readonly="disableSubject"
                    :class="{'input_lock':disableSubject,'input_unlock':!disableSubject}"
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="personal_data_textarea_container">
              <div class="personal_data_input_title">教學理念</div>
              <div>
                <div class="personal_data_textarea">
                  <textarea
                    v-model="teacherMessage.teachingIdea"
                    :readonly="disableSubject"
                    :class="{'input_lock':disableSubject,'input_unlock':!disableSubject}"
                  ></textarea>
                </div>
              </div>
            </div>

            <div style="clear:both;"></div>
            <!-- 按下修改資料後 input type="text"拿掉readonly class換成input_unlock   input type="checkbox"拿掉disabled="disabled"-->
            <!-- 按下儲存資料後 input增加readonly class換成input_lock   input type="checkbox"增加disabled="disabled"-->
            <div class="personal_data_btn" v-if="disableSubject" @click="disableSubject=false">修改資料</div>
            <div class="personal_data_btn" v-if="!disableSubject" @click="saveTeacherMessage">儲存資料</div>
            <div class="bottom_tip">僅限教學經歷和教學理念，如有其他資料有修改需求，請聯絡管理員</div>
          </div>
        </div>
        <!-- 基本資料end -->
        <!-- 備課資源 -->
        <div id="infor3" v-show="teacherTableId==3">
          <div class="member_lesson_infor_title">備課資源</div>

          <div>
            <div class="course_material_search_container">
              <div class="course_material_search">
                <input type="text" v-model="filePage.query" placeholder="搜尋關鍵字" />
                <img @click="handleFileChange" src="../../assets/image/search_icon.png" alt="搜尋" />
              </div>
            </div>
            <div class="course_material_title_contianer">
              <div class="course_material_title_type">類別</div>
              <div class="course_material_title_name">名稱</div>
              <div class="course_material_title_download">下載</div>
            </div>
            <div class="course_material_main">
              <div class="course_material_contianer" v-for="(file,k) in studyFileList" :key="k">
                <div class="course_material_type">{{file.type}}</div>
                <div class="course_material_name">{{file.title}}</div>
                <div class="course_material_download">
                  <a @click="downLoadVideoFile(file.downloadUrl)">
                    <img src="../../assets/image/download_icon.png" alt />
                  </a>
                </div>
              </div>
            </div>
            <div class="paginations">
              <el-pagination
                :hide-on-single-page="true"
                @current-change="handleFileChange"
                :current-page.sync="filePage.pageNum"
                :page-size="filePage.pageSize"
                layout="prev, pager, next, jumper"
                :total="fileTotals"
              ></el-pagination>
            </div>
          </div>

          <!-- <div class="course_video_container">
            <div v-if="studyVideoList&&studyVideoList.length">
              <div
                v-for="(item,i) in studyVideoList"
                :key="i"
                class="course_video_item"
                @click="openVedioPlay(i)"
              >
                <video width="100%" height="100%" :autoplay="false" controls>
                  <source :src="item.downloadUrl" />
                </video>
                <div class="course_video_name">{{item.title}}</div>
              </div>
            </div>
            <div v-else>暫無數據</div>
          </div>
          <div class="paginations">
            <el-pagination
              :hide-on-single-page="true"
              @current-change="handleVideoChange"
              :current-page.sync="videoPage.pageNum"
              :page-size="videoPage.pageSize"
              layout="prev, pager, next, jumper"
              :total="videoTotals"
            ></el-pagination>
          </div>-->
        </div>
        <!-- 備課資源end -->
        <!-- 上課錄影 -->
        <!-- <div id="infor4" v-if="teacherTableId==4">
          <div class="member_lesson_infor_title">上課錄影</div>

          <div class="video_record_tip">提醒您，上課錄影僅保留7天喔</div>

          <div class="video_record_title_contianer">
            <div class="video_record_title_name">上課時間內容</div>
            <div class="video_record_title_download">錄影下載</div>
          </div>

          <div class="video_record_main">
            <div v-if="courseList&&courseList.length">
              <div class="video_record_contianer" v-for="(c,k) in courseList" :key="k">
                <div class="video_record_name">{{c.classTitle}}</div>
                <div class="video_record_download">
                  <a :href="c.playbackUrl">
                    <img src="../../assets/image/download_icon.png" alt />
                  </a>
                </div>
              </div>
            </div>
            <div class="noDataTip" v-else>暫無數據</div>
          </div>
          <div class="paginations">
            <el-pagination
              :hide-on-single-page="true"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="playPage.pageNum"
              :page-sizes="pageList"
              :page-size="playPage.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="playPage.counts"
            ></el-pagination>
          </div>
        </div>-->
        <!-- 上課錄影end -->
      </div>
    </div>

    <!-- ... pup視窗-預計進度編寫 ... -->
    <div class="pup_container_progress" v-if="progressIsshow==1">
      <div class="pup_form_bg" @click="progressIsshow=0"></div>
      <div class="pup_form_container">
        <div class="progress_title">預計進度</div>
        <div class="progress_textarea">
          <textarea v-model="editProForm.expectedProgress"></textarea>
        </div>
        <img
          alt
          class="close_icon"
          @click="progressIsshow=0"
          src="../../assets/image/close_icon_green.png"
        />
        <div class="pup_form_btn" @click="saveProgress">儲存編寫進度</div>
      </div>
    </div>
    <!-- ... pup視窗-預計進度編寫end ... -->
    <!-- ... pup視窗-上課打卡 ... -->
    <div class="pup_container_punch_in" v-if="progressIsshow==3">
      <div class="pup_form_bg" @click="progressIsshow=0"></div>
      <div class="pup_form_container">
        <div class="punch_title">目前時間</div>
        <div class="punch_day">{{timeClock.split(" ")[0]}}</div>
        <div class="punch_time">{{timeClock.split(" ")[1]}}</div>
        <div class="punch_tip">需在上課時間前15分鐘後20分鐘才可進行打卡</div>
        <img
          alt
          class="close_icon"
          @click="progressIsshow=0"
          src="../../assets/image/close_icon_green.png"
        />
        <!-- ... 上課打卡時間需在上課時間前後10分鐘才可進行打卡 ... -->
        <div class="pup_form_btn" v-if="punchAllowed" @click="successPunchIn">完成上課打卡</div>
        <div class="pup_form_btn_no" v-if="!punchAllowed">未到打卡時間</div>
        <!-- ... 打卡時間顯示此按鈕 ... -->
      </div>
    </div>
    <div class="pup_container_punch_in" v-if="progressIsshow==4">
      <div class="pup_form_bg" @click="progressIsshow=0"></div>
      <div class="pup_form_container">
        <div class="punch_title">目前時間</div>
        <div class="punch_day">{{timeClock.split(" ")[0]}}</div>
        <div class="punch_day">{{timeClock.split(" ")[1]}}</div>
        <img
          alt
          class="close_icon"
          @click="progressIsshow=0"
          src="../../assets/image/close_icon_green.png"
        />
        <div class="pup_form_btn" v-if="punchAllowed" @click="successPunchOut">完成下課打卡</div>
        <div class="pup_form_btn_no" v-else>完成下課打卡</div>
        <!-- ... 打卡時間顯示此按鈕 ... -->
      </div>
    </div>
    <!-- ... pup視窗-上課打卡end ... -->
    <!-- ... pup視窗-填寫上課回報 ... -->
    <div class="pup_container_class_report" v-if="progressIsshow==2">
      <div class="pup_form_bg" @click="progressIsshow=0"></div>
      <div class="pup_form_container">
        <div class="report_class_title">上課回報</div>
        <div class="report_class_textarea">
          <textarea v-model="classForm.teacherReturnInClass"></textarea>
        </div>
        <!-- <div>
          <el-radio-group v-model="classForm.haveAcademic">
            <el-radio :label="true">有作業</el-radio>
            <el-radio :label="false">無作業</el-radio>
          </el-radio-group>
        </div>
        <div v-if="classForm.haveAcademic">
          <div class="report_class_title">作業內容</div>
          <div class="report_class_textarea">
            <textarea v-model="classForm.academicText"></textarea>
          </div>
        </div> -->
        <div class="report_class_tip">送出後將無法修改</div>

        <img
          alt
          class="close_icon"
          @click="progressIsshow=0"
          src="../../assets/image/close_icon_green.png"
        />
        <div
          class="pup_form_btn"
          v-if="orderItemList.teacherIsReturn"
          style="background:#bbb9b9"
        >送出上課回報</div>
        <div class="pup_form_btn" v-else @click="sendTeaReturn">送出上課回報</div>
      </div>
    </div>
    <!-- ... pup視窗-填寫上課回報end ... -->
    <!-- ... pup視窗-上課詳細資訊 ... -->
    <div class="member_pup_class_detail" v-if="progressIsshow==6">
      <div class="member_pup_bg"></div>
      <div class="member_pup_container">
        <div class="member_pup_title">上課詳細資訊</div>
        <div class="member_pup_main">
          <div class="pup_class_detail_main">
            <div class="pup_class_detail_text">
              <div class="title">課程名稱：</div>
              <div class="infor">{{orderItemList.classTitle}}</div>
            </div>
            <div class="pup_class_detail_text">
              <div class="title">上課時間：</div>
              <div
                class="infor"
              >{{orderItemList.classDate}}&nbsp;{{orderItemList.classTime}}&nbsp;合計{{orderItemList.classHour}}小時</div>
            </div>
            <div class="pup_class_detail_text">
              <div class="title">上課學生：</div>
              <div class="infor">{{orderItemList.studentName}}</div>
            </div>
            <div class="pup_class_detail_text">
              <div class="title">上課時間：</div>
              <div class="infor">{{new Date(orderItemList.teacherSignInTime).toLocaleString()}}完成打卡</div>
            </div>
            <div class="pup_class_detail_text">
              <div class="title">下課時間：</div>
              <div class="infor">{{new Date(orderItemList.teacherSignOutTime).toLocaleString()}}完成打卡</div>
            </div>
            <div class="pup_class_detail_text">
              <div class="title">本次積點：</div>
              <div class="infor">{{orderItemList.h}}點</div>
            </div>
            <div class="pup_class_detail_text">
              <div class="title">上課回報：</div>
              <div class="infor">{{orderItemList.teacherReturnInClass}}</div>
            </div>
            <div class="pup_class_detail_text">
              <div class="title">學生評價：</div>
              <div class="infor">
                {{orderItemList.effect}}
                <img src="../../assets/image/star_pic.png" alt />
              </div>
            </div>
          </div>
        </div>
        <img alt class="member_pup_close" src="../../assets/image/close_icon.png" />
      </div>
    </div>
    <!-- ... pup視窗-上課詳細資訊end ... -->

    <!-- ... pup視窗-備課資源 影音播放 ... -->
    <div class="pup_video_play_container" v-if="videoPlay">
      <div class="pup_video_play_bg" @click="videoPlay=false"></div>
      <div class="pup_video_play">
        <img
          @click="videoPlay=false"
          src="../../assets/image/pup_video_close.png"
          class="pup_video_close"
          alt
        />
        <img src="../../assets/image/video_cover.jpg" class="pup_video_cover" alt />
        <div class="pup_video_play_iframe"></div>
      </div>
    </div>
    <!-- ... pup視窗-備課資源 影音播放end ... -->
  </div>
</template>
<script>
import { validateRangeInOneMonth } from "element-ui/src/utils/date-util";
import {
  getTeacherSubject,
  getTeacherMod,
  getTeacherSubjectUserInfo,
  getTeacherUserInfo,
  getTeacherScheduleList,
  getTeacherFileList,
  getTeacherVideoList,
  getOrderEditEffect,
  getTeacherClassOrder,
  getEditProgress,
  getEditTeacherReturn,
  getTeacherSignIn,
  getTeacherSignOut,
  getTeaLastClassOrder,
  getTeaPlayurlList,
} from "../../api/api";
import classOrderTable from "./class_order_table";
import { Message } from "element-ui";
export default {
  data() {
    return {
      timeClock: "",
      haveAcademic: [],
      courseList: [],
      pageList: [10, 15, 20],
      lastOrderTip: {},
      orderItemList: {},
      editProForm: {},
      classForm: {},
      videoPlay: false,
      isStudy: false,
      noStudy: false,
      editReport: false, //上课汇报
      punchAllowed: true, //允许打卡
      punchTime: "", //打卡时间
      progressIsshow: 0, //填写进度
      teacherTableId: 1,
      classOrderData: [],
      classDetailList: {},
      subjectList: [],
      disableSubject: true, //修改资料禁止
      weeksList: ["日", "一", "二", "三", "四", "五", "六"],
      chNumber: [
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
        "十",
        "十一",
        "十二",
      ],
      teacherMessage: {},
      subjectBoolean: [],
      studyFileList: [],
      studyVideoList: [],
      playPage: { pageSize: 10, pageNum: 1 },
      videoPage: { pageSize: 10, pageNum: 1 },
      filePage: { pageSize: 10, pageNum: 1, query: "" },
      videoTotals: 0,
      fileTotals: 0,
    };
  },
  components: { classOrderTable },
  watch: {
    isStudy(nV) {
      if (nV) {
        this.noStudy = false;
      }
    },
    noStudy(nV) {
      if (nV) {
        this.isStudy = false;
      }
    },
  },
  created() {
    this.getTeacherInfo();
    this.getLastClassOrder();
    this.getSubjectLeasson();
    this.getFileList();
    this.getReadyFile();
    // this.getPlaylist();
  },
  methods: {
    //上課錄影列表
    async getPlaylist() {
      const res = await getTeaPlayurlList(this.playPage);
      if (res && res.success == 1) {
        this.courseList = res.data ? res.data.data : [];
        this.playPage.counts = res.data.count;
      }
    },
    //開課提醒數據
    async getLastClassOrder() {
      const res = await getTeaLastClassOrder();
      if (res && res.success == 1) {
        this.lastOrderTip = res.data;
      }
    },
    //老师课程列表
    async getTeacherClassList(y, m, d) {
      let logMonth = m < 10 ? `${y}-0${m}` : `${y}-${m}`;
      const res = await getTeacherClassOrder({ logMonth });
      if (res && res.success == 1) {
        this.setClassOrderArr(res.data.Data);
        this.setTeaclassDetail(d);
      }
    },
    setOrderFromChild(item) {
      this.orderItemList = item;
      if (this.progressIsshow == 1) {
        this.editProForm.expectedProgress = item.expectedProgress;
      }
      if (this.progressIsshow == 2) {
        const {
          teacherReturnInClass,
          haveAcademic,
          academicText,
        } = this.orderItemList;
        this.classForm = { teacherReturnInClass, haveAcademic, academicText };
      }
    },
    setClassOrderArr(arr) {
      this.classOrderData = arr;
    },
    setTeaclassDetail(d) {
      this.classDetailList = this.classOrderData[d];
    },
    //控制彈窗
    changeDialogId(k) {
      this.progressIsshow = k;
      // if (k == 3) {
      //   this.startPunchIn(0);
      // }
      // if (k == 4) {
      //   this.startPunchIn(1);
      // }
    },
    //判斷上課打卡時間
    startPunchIn(status) {
      // console.log(this.orderItemList);
      //status 0 上课打卡 1 下课打卡
      const date = this.orderItemList.classDate;
      let time = this.orderItemList.classStartTime,
        end_time = this.orderItemList.classEndTime;
      if (status == 0) {
        this.setPunchStatus(time, 10);
      } else {
        this.setPunchStatus(end_time, 15);
      }
    },
    setPunchStatus(time, loop) {
      // let nowDay = new Date().toLocaleDateString();
      let allowTime = new Date(time).getTime();
      let currentTime = new Date().getTime();
      let max = loop == 10 ? allowTime : currentTime,
        min = loop == 15 ? allowTime : currentTime;
      let Tolerance = Math.round((max - min) / 1000 / 60);
      if (Tolerance <= loop && Tolerance > 0) {
        this.punchAllowed = true;
      } else {
        this.punchAllowed = false;
      }
    },
    //重新获取课表
    getClassListAgain() {
      let dateL = this.$refs["classOrder"].currentDate;
      let month = new Date().getMonth(),
        day = new Date().getDate(); //console.log(m,d,"curr",month,day)
      if (dateL[1] == month && dateL[2] == day) {
        this.$refs["classOrder"] &&
          this.$refs["classOrder"].getTeaClassByDayList &&
          this.$refs["classOrder"].getTeaClassByDayList();
      } else {
        this.getTeacherClassList(dateL[1] + 1, dateL[2] - 1);
      }
    },
    //送出回報
    async sendTeaReturn() {
      this.classForm.OrderId = this.orderItemList.classOrderId;
      const res = await getEditTeacherReturn(this.classForm);
      if (res && res.success == 1) {
        Message({ message: "回報發送成功" });
        this.getClassListAgain();
        this.progressIsshow = 0;
      }
    },
    //存儲編輯進度
    async saveProgress() {
      this.editProForm.OrderId = this.orderItemList.classOrderId;
      const res = await getEditProgress(this.editProForm);
      if (res && res.success == 1) {
        this.getClassListAgain();
        Message({ message: "預計進度編寫成功" });
        this.progressIsshow = 0;
      }
    },
    //上課打卡
    async successPunchIn() {
      const res = await getTeacherSignIn({
        orderId: this.orderItemList.classOrderId,
      });
      if (res && res.success == 1) {
        this.getClassListAgain();
        Message({
          type: "success",
          message: "上課打卡成功",
        });
        this.progressIsshow = 0;
      }
    },
    //下课打卡
    async successPunchOut() {
      const res = await getTeacherSignOut({
        orderId: this.orderItemList.classOrderId,
      });
      if (res && res.success == 1) {
        this.getClassListAgain();
        Message({
          type: "success",
          message: "下課打卡成功",
        });
        this.progressIsshow = 0;
      }
    },
    //获取课程资源列表
    async getFileList() {
      try {
        const res = await getTeacherFileList(this.filePage);
        if (res && res.code == 200) {
          this.studyFileList = res.data.data;
          this.fileTotals = count;
        }
      } catch (err) {}
    },
    async getReadyFile() {
      try {
        const video_res = await getTeacherVideoList(this.videoPage);
        if (video_res && video_res.code == 200) {
          this.studyVideoList = video_res.data.data;
          this.videoTotals = count;
        }
      } catch (err) {}
    },
    //fenye
    handleSizeChange(val) {
      this.playPage.pageSize = val;
      this.getPlaylist();
    },
    handleCurrentChange() {
      this.playPage.pageNum = val;
      this.getPlaylist();
    },
    //备课资源文件分页
    handleVideoChange(val) {
      this.videoPage.pageNum = page;
      this.getReadyFile();
    },
    handleFileChange(val) {
      this.filePage.pageNum = page;
      this.getFileList();
    },
    //教师基本资料
    async getTeacherInfo() {
      const res = await getTeacherUserInfo();
      if (res) {
        this.teacherMessage = res.data;
      }
    },
    //
    //获取教师科目列表
    async getSubjectLeasson() {
      const res = await getTeacherSubject();
      if (res && res.code == 200) {
        this.subjectList = res.data;
      }
    },
    //下载非视频文件资源
    downLoadVideoFile(blobUrl) {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style.display = "none";
      a.href = blobUrl;
      a.click();
      document.body.removeChild(a);
    },
    async saveTeacherMessage() {
      try {
        const res = await getTeacherMod(this.teacherMessage);
        // const subject_res = await getTeacherSubjectUserInfo(this.subjectList);
        if (res && res.success == 1) {
          this.disableSubject = true;
          message({
            type: "success",
            message: "基本資料修改完成",
          });
        }
      } catch (err) {}
    },
    //打开视频播放窗口
    openVedioPlay(i) {
      // this.videoPlay = true;
    },
    //获取当天排课情况
    getStudentClass(date) {
      let day = date.getDate();
      let curr = new Date().getDate();
      console.log(date, day, curr);
    },
    //上课状态
    toClassStatus(id) {
      let text =
        id == 0 ? "已取消" : id == 1 ? "準備中" : id == 2 ? "上課中" : "已完成";
      return text;
    },
    lastTimeFilter(id, t) {
      if (id == 1) {
        if (t) {
          return new Date(t).toLocaleDateString().substr(5);
        }
      }
      if (id == 2) {
        if (t) {
          return t.split("-")[0];
        }
      }
      if (id == 3) {
        if (t) {
          return parseInt(t.substr(0, 2)) > 12 ? "pm" : "am";
        }
      }
      return "";
    },
    setTimeVal() {
      let date = new Date();
      let d = date.toLocaleDateString(),
        h = date.getHours(),
        m = date.getMinutes(),
        s = date.getSeconds();
      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;
      this.timeClock = d + " " + h + ":" + m + ":" + s;
    },
  },
  mounted() {
    this.setTimeVal();
    this.timeInterVal = setInterval(() => this.setTimeVal(), 1000);
  },
  destroyed() {
    clearInterval(this.timeInterVal);
    this.timeInterVal = null;
  },
};
</script>
<style>
.class-study-center {
  width: 20px;
  margin: 6px auto;
  border-bottom: 4px solid #0ba96c;
}
.class-study {
  width: 20px;
  margin: 6px auto;
  border-bottom: 4px solid #202020;
}
.paginations {
  display: flex;
  justify-content: center;
  width: auto;
  margin: 12px auto;
}
.bottom_tip {
  width: 100%;
  position: absolute;
  bottom: 17px;
  left: 0;
  padding: 0 20px;
  font-size: 14px;
  color: #202020;
  text-align: center;
}
</style>